<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <selectComp
              placeholder="报修来源"
              :hval="type"
              :data="typeList"
              @change="typeChange"
            />
            <inputComp
              placeholder="群聊号"
              :hval="groupIdN"
              @blur="groupIdBlur"
            />
            <inputComp
              placeholder="报修人"
              :hval="repairUserName"
              @blur="repairUserNameBlur"
            />
            <inputComp
              placeholder="报修人联系方式"
              :hval="mobile"
              @blur="mobileBlur"
            />
            <inputComp
              placeholder="处理人"
              :hval="remoteUser"
              @blur="remoteUserBlur"
            />
            <dateComp :hval="date" @change="dateChange" />
            <btnGroup @search="search" @reset="reset" />
            <btnComp
              @click="myHandleClick"
              >{{ myRemote ? '全部的处理' : '我的处理' }}</btnComp
            >
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          v-loading="loading"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
          <el-table-column slot="operate" label="操作" fixed="right" width="160">
            <template v-slot="scope">
              <btnComp
                btnType="text"
                v-if="scope.row.inGroupButton"
                @click="enterChat(scope.row.orderId)"
                >进入聊天</btnComp
              >
              <btnComp
                btnType="text"
                @click="lookHistoryRecord(scope.row.groupId)"
                >查看消息记录</btnComp
              >
            </template>
          </el-table-column>
        </tableComp>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawerShowC"
      size="90%"
      title="群聊窗口"
      direction="rtl"
      :before-close="handleClose"
    >
      <aboutChat pageName="group-chat" />
    </el-drawer>
    <!-- 消息记录 -->
    <historyMsg
      :isShow="recordShow"
      :clickGroupId="clickGroupId"
      @close="recordShow = false"
    />
  </div>
</template>
<script>
import aboutChat from '../all-order/about-chat'
import historyMsg from './historyMsg'
import { groupChatList } from '@/api'
import { mapActions, mapMutations, mapState } from 'vuex'
// import chatMixin from '@/mixin/chat-mixin'
export default {
  // mixins: [chatMixin],
  components: {
    aboutChat,
    historyMsg
  },
  data () {
    return {
      loading: false,
      drawerShowC: false,
      recordShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      clickGroupId: '',
      type: '',
      repairUserName: '',
      mobile: '',
      groupIdN: '',
      remoteUser: '',
      date: [],
      startTime: '',
      endTime: '',
      myRemote: 0,
      acceptOrderId: '',
      typeList: [
        { label: '客服报修', value: 0 },
        { label: '扫码报修', value: 1 }
      ],
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'groupId', label: '群聊号' },
        { prop: 'repairUserName', label: '报修人' },
        { prop: 'typeName', label: '报修来源' },
        { prop: 'repairCode', label: '报修单号' },
        { slot: 'code' },
        { prop: 'mobile', label: '报修人联系方式' },
        { prop: 'itemCount', label: '设备台数' },
        { prop: 'statusName', label: '群状态' },
        { prop: 'createdTime', label: '报修时间' },
        { prop: 'remoteUser', label: '当前处理人' }
      ]
    }
  },
  computed: {
    ...mapState(['loginUserInfo'])
  },
  mounted () {
    this.$bus.$on('onClickMsgItem', this.onClickMsgItem)
    const { orderId } = this.$route.params
    this.acceptOrderId = orderId
    // 点击铃铛消息跳转过来的就自动出现弹窗
    if (this.acceptOrderId) {
      this.enterChat(this.acceptOrderId)
      this.acceptOrderId = ''
    }
    this.getList()
  },
  methods: {
    onClickMsgItem (val) {
      const { orderId } = val
      this.acceptOrderId = orderId
      // 点击铃铛消息跳转过来的就自动出现弹窗
      if (this.acceptOrderId) {
        this.enterChat(this.acceptOrderId)
        this.acceptOrderId = ''
      }
      this.getList()
    },
    ...mapMutations(['SET_DRAWER_SHOW']),
    ...mapActions('im', ['getMessageList', 'getUnReadMsgList']),
    async getList () {
      const params = {
        page: this.curPage,
        pageSize: this.pageSize,
        type: this.type,
        repairUserName: this.repairUserName,
        mobile: this.mobile,
        groupId: this.groupIdN,
        remoteUser: this.remoteUser,
        startTime: this.startTime,
        endTime: this.endTime,
        myRemote: this.myRemote
      }
      this.loading = true
      const res = await groupChatList(params)
      this.loading = false
      this.tableData = res.data.list
      this.total = res.data.totalCount
    },
    typeChange (val) {
      this.type = val
    },
    groupIdBlur (val) {
      this.groupIdN = val
    },
    repairUserNameBlur (val) {
      this.repairUserName = val
    },
    mobileBlur (val) {
      this.mobile = val
    },
    remoteUserBlur (val) {
      this.remoteUser = val
    },
    dateChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    myHandleClick () {
      this.myRemote = this.myRemote === 0 ? 1 : 0
      this.getList()
    },
    // 进入群聊
    enterChat (id) {
      this.$store
        .dispatch('im/enterChatAction', id)
        .then(res => {
          this.getUnReadMsgList()
          this.drawerShowC = true
          this.SET_DRAWER_SHOW(true)
        })
        .catch(_ => {
          this.drawerShowC = false
          this.SET_DRAWER_SHOW(false)
        })
    },
    // 查看消息记录
    lookHistoryRecord (id) {
      this.clickGroupId = id
      this.recordShow = true
    },
    handleClose () {
      this.SET_DRAWER_SHOW(false)
      this.drawerShowC = false
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.type = ''
      this.repairUserName = ''
      this.mobile = ''
      this.groupIdN = ''
      this.remoteUser = ''
      this.date = []
      this.startTime = ''
      this.endTime = ''
      this.myRemote = 0
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  background-color: #fff;
  display: flex;
  align-items: center;
}
</style>
