<template>
  <dialogComp
    title="查看消息记录"
    width="1000px"
    :isShow="isShow"
    :isShowFooter="false"
    @close="close"
  >
    <tableComp
      :data="tableData"
      :col-configs="theadName"
      :total="total"
      :currentPage="curPage"
      :pageSize="pageSize"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <el-table-column slot="content" label="内容">
        <template v-slot="scope">
          <div class="text" v-if="scope.row.type === 0">
            <span>{{ scope.row.content }}</span>
          </div>
          <div class="image" v-else-if="scope.row.type === 1">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.content"
              :preview-src-list="[scope.row.content]"
            >
            </el-image>
          </div>
          <div class="video" v-else>
            <a :href="scope.row.content" target="_blank">链接地址</a>
          </div>
        </template>
      </el-table-column>
    </tableComp>
  </dialogComp>
</template>
<script>
import { groupChatHistoryMsg } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    clickGroupId: {}
  },
  data () {
    return {
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      theadName: [
        { slot: 'content' },
        { prop: 'userName', label: '发言人' },
        { prop: 'createdTime', label: '发言时间' },
        { prop: 'typeName', label: '内容类型' }

      ]
    }
  },
  watch: {
    isShow (val) {
      val && this.getList()
    }
  },
  methods: {
    async getList () {
      const params = {
        groupId: this.clickGroupId,
        page: this.curPage,
        pageSize: this.pageSize
      }
      const res = await groupChatHistoryMsg(params)
      const { list, totalCount } = res.data
      this.tableData = list
      this.total = totalCount
    },
    close () {
      this.$emit('close')
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
